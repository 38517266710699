/*============================================
		Contact Map
==============================================*/
	function loadGmap(){

	if($('#gmap').length){

		var map;
		var mapstyles = [ { "stylers": [ { "saturation": -10 } ] } ];

		var infoWindow = new google.maps.InfoWindow;

		var pointLatLng = new google.maps.LatLng(mapPoint.lat, mapPoint.lng);

		var mapOptions = {
			zoom: mapPoint.zoom,
			center: pointLatLng,
			zoomControl : true,
			panControl : false,
			streetViewControl : false,
			mapTypeControl: false,
			overviewMapControl: false,
			scrollwheel: false,
			styles: mapstyles
		}

		map = new google.maps.Map(document.getElementById("gmap"), mapOptions);

		var marker = new google.maps.Marker({
			position: pointLatLng,
			map: map,
			title:mapPoint.linkText,
			icon: mapPoint.icon
		});

		var mapLink = 'https://www.google.com/maps/preview?ll='+mapPoint.lat+','+mapPoint.lng+'&z=14&q='+mapPoint.mapAddress;

		var html = '<div class="infowin">'
				+ mapPoint.infoText
				+ '<a href="'+mapLink+'" target="_blank">'+mapPoint.linkText+'</a>'
				+ '</div>';

		google.maps.event.addListener(marker, 'mouseover', function() {
			infoWindow.setContent(html);
			infoWindow.open(map, marker);
		});

		google.maps.event.addListener(marker, 'click', function() {
			window.open(mapLink,'_blank');
		});

		}
	}



$(document).ready(function(){

	
	$("#rss-feeds").rss("https://www.nationalehulpgids.nl/nieuws/feed/", {
          limit: 3,
          
          entryTemplate: "<div class='col-md-4'><span class='list-date'><span class='list-day'>{dag}</span><span class='list-month'>{date}</span></span><div class='card card-testimonial'><div class='card-body'><h4>{title}</h4><p class='card-description truncate'>{bodyPlain}</p><br><a href='{url}' class='btn btn-info' target='_blank'>lees meer <i class='fa fa-caret-right'></i></a></div></div></div>",
          //dateFormat: 'ddd, DD MMM YYYY',
          dateFormat: 'MMM',

        
          tokens: {
            dag: function(entry, tokens) {
              return new Date(entry.publishedDate).getDate()
            }
          }
          
        })
		

    //materialDocumentReady();
    //if($("#contactUsMap").length) {
    //    materialKitDemo.initContactUsMap();
    //}

});

function add_js_input(form_id){
	$('<input>').attr({
		type: 'hidden',
		id: 'captcha',
		name: 'captcha',
		value: 'honey'}).appendTo(form_id);
}

$(window).on("load", function (e) {
	
	add_js_input('#contact_form');
	
	if(window.validate_contact_form){
		validate_contact_form();
	}
	loadGmap();

});

$(document).on('click', '[data-toggle="lightbox"]', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox();
});